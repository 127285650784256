import React from "react"
import PropTypes from "prop-types"
import LocationFilterRow from "@templates/locations/components/LocationFilterRow"
import LocationResultsList from "@templates/locations/components/LocationResultsList"
import LocationMap from "@components/common/LocationMap"
import HeadingText from "@components/common/HeadingText"

import classnames from "classnames"

import language from "@language"
import { haversine_distance } from "@utilities/mapUtils"

import "./style.scss"

class LocationFilterMapSection extends React.Component {
  constructor(props) {
    super(props)

    this.client = null
    this.map = null
    this.timeout = null

    this.setStringFilter = this.setStringFilter.bind(this)
    this.setLocationTypeId = this.setLocationTypeId.bind(this)
    this.filterLocations = this.filterLocations.bind(this)
    this.filterByLocationTypeId = this.filterByLocationTypeId.bind(this)
    this.handleGoogleMapsLoaded = this.handleGoogleMapsLoaded.bind(this)
    this.clearResultMessage = this.clearResultMessage.bind(this)
    this.clearSearchResult = this.clearSearchResult.bind(this)
    this.getClosestLocations = this.getClosestLocations.bind(this)

    this.state = {
      filteredLocations: props.locations,
      stringFilter: null,
      locationTypeIdFilter: null,
      searchResult: null,
      searchResultCoords: null,
      resultMessage: null,
      resultStatus: null,
    }
  }

  componentDidMount() {
    this.filterLocations()
  }

  async sendGeocodeRequest(address = "") {
    this.timeout = null
    // https://developers.google.com/maps/documentation/javascript/examples/place-search
    const geocoder = new this.client.Geocoder()

    try {
      geocoder.geocode(
        {
          address: address,
        },
        results => {
          const result = results[0]

          if (!result) {
            // handle empty results
            this.setState({
              searchResult: null,
              searchResultCoords: null,
              resultMessage: language[this.props.language].no_results,
              resultStatus: 200,
            })
          } else {
            // set results

            this.setState(
              {
                // stringFilter: result.formatted_address,
                searchResult: result,
                searchResultCoords: {
                  lat: result.geometry.location.lat(),
                  lng: result.geometry.location.lng(),
                },
              },
              () => {
                this.filterLocations(() => {
                  // set map bounds
                  const locationCoords = this.state.filteredLocations.map(
                    location => {
                      return {
                        lat: location.acf.map.latitude,
                        lng: location.acf.map.longitude,
                      }
                    }
                  )

                  this.mapCenterOnBounds([
                    this.state.searchResultCoords,
                    ...locationCoords,
                  ])
                })
              }
            )
          }
        }
      )
    } catch (error) {
      this.setState({
        resultMessage: language[this.props.language].error_occured,
        resultStatus: 500,
      })
      console.log(error)
    }
  }

  handleGoogleMapsLoaded({ map, maps }) {
    this.client = maps
    this.map = map
  }

  mapCenterOnBounds(latLngCoords = []) {
    if (!(this.map && this.client)) return

    const bounds = new this.client.LatLngBounds()

    latLngCoords.forEach(coord => {
      bounds.extend(coord)
    })

    this.map.fitBounds(bounds)
  }

  clearResultMessage(callback = null) {
    this.setState(
      {
        resultMessage: null,
        resultStatus: null,
      },
      () => {
        if (callback) callback()
      }
    )
  }

  clearSearchResult(callback = null) {
    this.setState(
      {
        searchResult: null,
        searchResultCoords: null,
      },
      () => {
        if (callback) {
          callback()
        }
      }
    )
  }

  setStringFilter(string) {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }
    this.clearResultMessage(() => {
      if (!string) {
        this.setState(
          {
            stringFilter: "",
          },
          () => this.clearSearchResult(this.filterLocations)
        )
      } else {
        this.setState(
          {
            stringFilter: string,
            searchResult: null,
            searchResultCoords: null,
          },
          () => {
            this.timeout = setTimeout(
              () => this.sendGeocodeRequest(string),
              800
            )
          }
        )
      }
    })
  }

  getClosestLocations(resultLatLng = { lat: 0, lng: 0 }, locations = []) {
    return locations
      .map(l => l)
      .sort((a, b) => {
        const aLatLng = {
          lat: (a.acf.map || {}).latitude,
          lng: (a.acf.map || {}).longitude,
        }

        const bLatLng = {
          lat: (b.acf.map || {}).latitude,
          lng: (b.acf.map || {}).longitude,
        }

        const aDistance = haversine_distance(resultLatLng, aLatLng)
        const bDistance = haversine_distance(resultLatLng, bLatLng)

        return aDistance - bDistance
      })
  }

  setLocationTypeId(option) {
    this.clearResultMessage(() => {
      const value = (option || {}).value
      this.setState(
        {
          locationTypeIdFilter: value,
        },
        () => {
          this.filterLocations()
        }
      )
    })
  }

  filterByLocationTypeId(locationTypeIdFilter, locations = []) {
    if (!locationTypeIdFilter) return this.props.locations
    return locations.filter(
      location => location.acf.type.id === locationTypeIdFilter
    )
  }

  filterLocations(callback = null) {
    this.setState(
      oldState => {
        if (oldState.searchResultCoords) {
          return {
            filteredLocations: this.getClosestLocations(
              oldState.searchResultCoords,
              this.filterByLocationTypeId(
                oldState.locationTypeIdFilter,
                this.props.locations
              )
            ),
          }
        } else {
          return {
            filteredLocations: this.filterByLocationTypeId(
              this.state.locationTypeIdFilter,
              this.props.locations
            ),
          }
        }
      },
      () => {
        if (callback) {
          callback()
        }
      }
    )
  }

  render() {
    return (
      <div
        className={classnames(
          "location-filter-map-section",
          this.props.className
        )}
      >
        <div className="page-wrap">
          {this.props.title && (
            <HeadingText
              className="profile-location-section__work-title"
              size="md"
              color="dark"
              text={this.props.title}
            />
          )}
        </div>
        <div className="location-filter-map-section__row">
          <div className="location-filter-map-section__left-col">
            {!!this.props.showFilters && (
              <LocationFilterRow
                className="location-filter-map-section__filter-row--desktop"
                onLocationTypeChange={this.setLocationTypeId}
                onAddressChange={e => this.setStringFilter(e.target.value)}
                locationTypes={this.props.locationTypes}
                locationsTotal={this.state.filteredLocations.length}
                resultMessage={this.state.resultMessage}
                resultStatus={this.state.resultStatus}
                value={this.state.stringFilter}
              />
            )}

            <LocationResultsList locations={this.state.filteredLocations} />
          </div>
          <div className="location-filter-map-section__right-col">
            {!!this.props.showFilters && (
              <LocationFilterRow
                className="location-filter-map-section__filter-row--mobile"
                onLocationTypeChange={this.setLocationTypeId}
                onAddressChange={e => this.setStringFilter(e.target.value)}
                locationTypes={this.props.locationTypes}
                locationsTotal={this.state.filteredLocations.length}
                locationTypeIdFilter={this.state.locationTypeIdFilter}
                resultMessage={this.state.resultMessage}
                resultStatus={this.state.resultStatus}
                value={this.state.stringFilter}
              />
            )}

            <LocationMap
              className="location-filter-map-section__map"
              locations={this.state.filteredLocations}
              handleGoogleMapsLoaded={this.handleGoogleMapsLoaded}
              searchResultCoords={
                this.state.searchResult
                  ? {
                      lat: this.state.searchResult.geometry.location.lat(),
                      lng: this.state.searchResult.geometry.location.lng(),
                    }
                  : null
              }
            />
          </div>
        </div>
      </div>
    )
  }
}

LocationFilterMapSection.propTypes = {
  className: PropTypes.string,
  language: PropTypes.string,
  locations: PropTypes.array,
  locationTypes: PropTypes.array,
  showFilters: PropTypes.bool,
  title: PropTypes.string,
}
LocationFilterMapSection.defaultProps = {
  language: "es",
  locations: [],
  locationTypes: [],
  showFilters: false,
}

export default LocationFilterMapSection
