import React from "react"
import PropTypes from "prop-types"

import HeadingText from "@components/common/HeadingText"
import BodyText from "@components/common/BodyText"
import IconLinkButton from "@components/common/IconLinkButton"
import LocationTag from "@components/common/LocationTag"

import { ReactComponent as ChevronRight } from "@images/icons/chevron-right.svg"
import { cityStateZip } from "@utilities/copyUtils"
import language from "@language"

import "./style.scss"
import UniversalLink from "../../../../components/common/UniversalLink"

const LocationResultCard = props => {
  return (
    <div className="location-result-card">
      <div className="location-result-card__wrapper">
        <div className="location-result-card__image-container">
          <LocationTag
            className="location-result-card__tag"
            typeLabel={props.typeLabel}
          />

          <img
            className="location-result-card__image height-responsive-image"
            alt={(props.image || {}).altText}
            src={(props.image || {}).sourceUrl}
          />
        </div>
        <div className="location-result-card__content-container">
          <div className="location-result-card__title">
            {
              <UniversalLink href={props.uri}>
                <HeadingText
                  className="location-result-card__title-text"
                  color="primary-dark"
                  size="sm"
                  text={
                    props.typeDescription
                  }
                />
                <HeadingText
                  className="location-result-card__title-text hover-underline"
                  color="primary"
                  size="sm"
                  text={props.title}
                />
              </UniversalLink>
            }
          </div>
          <div className="location-result-card__address">
            <BodyText size="sm" color="dark-gray" text={props.streetAddress} />
            <BodyText
              size="sm"
              color="dark-gray"
              text={cityStateZip(
                props.city,
                props.stateAbbreviation,
                props.zipCode
              )}
            />
          </div>
          <div className="location-result-card__cta-row">
            <IconLinkButton
              icon={<ChevronRight />}
              className="location-result-card__cta"
              uri={`https://www.google.com/maps/search/?api=1&query=${props.placeAddress}&query_place_id=${props.placeId}`}
              text={language[props.language].directions}
            />
            <IconLinkButton
              icon={<ChevronRight />}
              className="location-result-card__cta"
              uri={props.uri}
              text={language[props.language].more_info}
            />
          </div>
        </div>
      </div>
      <hr className="divider divider__light-gray" />
    </div>
  )
}

LocationResultCard.propTypes = {
  language: PropTypes.string,
  typeId: PropTypes.string,
  typeLabel: PropTypes.string,
  typeDescription: PropTypes.string,
  image: PropTypes.shape({
    altText: PropTypes.string,
    sourceUrl: PropTypes.string,
  }),
  title: PropTypes.string,
  streetAddress: PropTypes.string,
  city: PropTypes.string,
  stateAbbreviation: PropTypes.string,
  zipCode: PropTypes.string,
  uri: PropTypes.string,
}
LocationResultCard.defaultProps = {
  image: {
    altText: "",
    sourceUrl: "",
  },
  language: "es",
}

export default LocationResultCard
