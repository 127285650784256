import React from "react"
import { graphql } from "gatsby"
import gql from "graphql-tag"
import withPreview from "@components/withPreview"

import Layout from "@components/layout"
import SEO from "@components/seo"
import { filterPageForTemplate } from "@templates/utilities/templateUtils"
import SplitTextImageSection from "@templates/homepage/components/SplitTextImageSection"
import SimpleBreadcrumbHero from "@components/common/SimpleBreadcrumbHero"
import LocationTypeList from "@templates/locations/components/LocationTypeList"
import LocationFilterMapSection from "./components/LocationFilterMapSection"

export const LocationIndex = props => {
  const page =
    !!props.preview && !!props.preview.location
      ? props.preview.location.preview.node
      : filterPageForTemplate(
        props.data.allWpPage.edges,
        props.pageContext.wordpressUri
      )

      console.log(page)
      
  const locations = props.data.allWpLocation.edges
    .map(l => l.node)
    .filter(l =>
      page.acfLocationsPage.locationTypes.map(t => t.id).includes(l.acf.type.id)
    )

  return (
    <Layout>
      <SEO title={page.acfPageMeta.metaTitle} />
      <div className="page-fade-in">
        <SimpleBreadcrumbHero
          overline={page.acfLocationsPage.locationHero.overline}
          pageTitle={page.acfPageMeta.metaTitle}
          title={page.acfLocationsPage.locationHero.title}
          image={page.acfLocationsPage.locationHero.image}
          overlayType="full"
        />
        {
          // location type list
        }
        {page.acfLocationsPage.locationTypes ? (
          <LocationTypeList
            locationTypes={page.acfLocationsPage.locationTypes}
            locations={locations}
          />
        ) : ('')}

        <LocationFilterMapSection
          locationTypes={page.acfLocationsPage.locationTypes}
          locations={locations}
          showFilters={true}
        />

        {
          // Career section
        }
        <SplitTextImageSection
          image={page.acfLocationsPage.lcareerSection.image}
          imageSide="right"
          imageSize="lg"
          title={page.acfLocationsPage.lcareerSection.title}
          subcopy={page.acfLocationsPage.lcareerSection.subcopy}
          cta2={page.acfLocationsPage.lcareerSection.cta}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query LocationsPageQuery {
    allWpPage(filter: { acfPageMeta: { pageTemplate: { eq: "locations" } } }) {
      edges {
        node {
          uri
          acfPageMeta {
            metaDescription
            metaImage {
              altText
              sourceUrl
              uri
            }
            metaTitle
          }
          acfLocationsPage {
            locationTypes {
              name
              id
              description
            }
            locationHero {
              overline
              title
              image {
                altText
                sourceUrl
              }
            }
            lcareerSection {
              title
              subcopy
              image {
                sourceUrl
                altText
              }
              cta {
                title
                url
              }
            }
          }
        }
      }
    }
    allWpLocation(filter: { acf: { publishFinal: { eq: true } } }) {
      edges {
        node {
          title
          uri
          acf {
            order
            locationImage {
              altText
              sourceUrl
            }
            type {
              id
              name
              slug
              description
            }
            specializedClinics {
              department {
                id
                name
              }
              sublocation {
                ... on WpLocation {
                  id
                  title
                  uri
                  acf {
                    publishFinal
                  }
                }
              }
            }
            city
            state
            stateAbbreviation
            streetAddress
            telephone
            zipCode
            map {
              latitude
              longitude
              placeId
            }
          }
        }
      }
    }
  }
`

const PREVIEW_QUERY = gql`
  query PreviewLocationQuery($id: ID!) {
    page(id: $id, idType: DATABASE_ID) {
      preview {
        node {
          uri
          acfPageMeta {
            metaDescription
            metaImage {
              altText
              sourceUrl
              uri
            }
            metaTitle
          }
          acfLocationsPage {
            locationTypes {
              name
              id
              description
            }
            locationHero {
              overline
              title
            }
            lcareerSection {
              title
              subcopy
              image {
                sourceUrl
                altText
              }
              cta {
                title
                url
              }
            }
          }
        }
      }
    }
  }
`

export default withPreview({ preview: PREVIEW_QUERY })(LocationIndex)
