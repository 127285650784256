import React from "react"
import PropTypes from "prop-types"

import FilterSelectNavigator from "@components/common/FilterSelectNavigator"
import BodyText from "@components/common/BodyText"

import language from "@language"
import classnames from "classnames"
import * as c from "@utilities/constants"

import "./style.scss"
import FormInput from "../../../../components/common/FormInput"

const LocationFilterRow = props => {
  const options = React.useMemo(() => {
    return props.locationTypes.map(type => {
      return { value: type.id, label: type.name }
    })
  }, [props.locationTypes])
  return (
    <div className={classnames("location-filter-row", props.className)}>
      <div className="location-filter-row__row">
        <FormInput
          className="location-filter-row__input"
          placeholder={language[props.language].write_your_address}
          onChange={props.onAddressChange}
          value={props.value || ""}
        />
        <FilterSelectNavigator
          className="location-filter-row__select"
          onChange={props.onLocationTypeChange}
          options={options}
          placeholder={language[props.language].filters}
          language={props.language}
          queryParamKey={c.LOCATION_TYPE_PARAM_KEY}
        />
        <BodyText
          className="location-filter-row__message"
          color={props.resultStatus === 500 ? "error" : "dark-gray"}
          size="sm"
          text={props.resultMessage}
        />
      </div>

      <hr className="location-filter-row__divider divider divider__light-gray" />
      <BodyText
        className="location-filter-row__label"
        size="sm"
        color="dark-gray"
        text={language[props.language].sentences.showingXResults(
          props.locationsTotal
        )}
      />
    </div>
  )
}

LocationFilterRow.propTypes = {
  className: PropTypes.string,
  locationTypes: PropTypes.array,
  language: PropTypes.string,
  onLocationTypeChange: PropTypes.func,
  onAddressChange: PropTypes.func,
  locationsTotal: PropTypes.number,
  locationTypeIdFilter: PropTypes.string,
  resultMessage: PropTypes.string,
  resultStatus: PropTypes.number,
  search: PropTypes.object,
}
LocationFilterRow.defaultProps = {
  locationTypes: [],
  locationsTotal: 0,
  language: "es",
  value: "",
}

export default LocationFilterRow
