import React from "react"
import PropTypes from "prop-types"

import HeadingText from "@components/common/HeadingText"
import UniversalLink from "@components/common/UniversalLink"
import language from "@language"
import classnames from "classnames"

import * as c from "@utilities/constants"

import "./style.scss"

const filterByLocationTypeId = (locations, id) => {
  const typeLocations = locations.filter(
    location => location.acf.type.id === id
  )
  if (typeLocations.every(l => !l.acf.order || l.acf.order === -1)) {
    // sort alphabetical by title
    return typeLocations.sort((a, b) => {
      return a.title < b.title ? -1 : b.title > a.title ? 1 : 0
    })
  } else {
    // sort by order (-1 at the bottom, 1, 2, 3 in order ascending)
    return typeLocations.sort((a, b) => {
      if (a.acf.order === -1) return 1
      if (b.acf.order === -1) return -1 // puts -1 at the bottom always
      return a.acf.order - b.acf.order
    })
  }
}

const hospitalsWithSpecializedCenters = (locations = []) => {
  const hospitals = locations.filter(l => (l.acf.type || {}).slug === c.TYPE_HOSPITAL_SLUG)
  // filter by type
  hospitals.filter(l => !!(l.acf.specializedClinics || {}).length)
  // sort by "orden"
  hospitals.sort((a, b) => {
    if (a.acf.order === -1) return 1
    if (b.acf.order === -1) return -1 // puts -1 at the bottom always
    return a.acf.order - b.acf.order
  })
  return hospitals
}


const LocationTypeList = props => {

  return (
    <div className="location-type-list">
      <div className="page-wrap">
        <div className="location-type-list__row">
          <div className="location-type-list__column">
            {props.locationTypes.map((type, index) => {
              // TODO Order by field value
              // console.log(type, props.locations)
              return (
                <div className="location-type-list__group">
                  <HeadingText
                    className="location-type-list__title"
                    size="md"
                    color="dark"
                    text={type.description}
                  />
                  {
                    filterByLocationTypeId(props.locations, type.id).map(
                      (location, index) => (
                        <UniversalLink
                          key={`location-type-list__item--${index}`}
                          href={location.uri}
                        >
                          <HeadingText
                            className={classnames(
                              "location-type-list__item text-link--green-hover"
                            )}
                            size={"xxl"}
                            color="primary"
                            text={location.title}
                          />
                        </UniversalLink>
                      )
                    )
                  }
                </div>
              )
            })}
          </div>
          <div
            className="location-type-list__column"
          >
            <HeadingText
              className="location-type-list__title"
              size="md"
              color="dark"
              text={language[props.language].specialized_centers}
            />
            {
              hospitalsWithSpecializedCenters(props.locations).map(l => {
                return <><HeadingText
                  className={classnames(
                    "location-type-list__subtitle"
                  )}
                  size={"md"}
                  color="primary-dark"
                  text={l.title}
                />
                  { l.acf.specializedClinics ? (
                    l.acf.specializedClinics.filter(center => ((center.sublocation || {}).acf || {}).publishFinal).map((center, index) => {
                      return <UniversalLink
                        key={`location-type-list__item--${index}`}
                        href={(center.sublocation || {}).uri}
                      ><HeadingText
                          className={classnames(
                            "location-type-list__item text-link--green-hover", "mb-20px"
                          )}
                          size={"lgr"}
                          color="primary"
                          text={(center.sublocation || {}).title}
                        />
                      </UniversalLink>
                    })) : ('')
                  }
                </>
              })
            }

          </div>
        </div>
      </div>
    </div>
  )
}

LocationTypeList.propTypes = {
  locations: PropTypes.array,
  locationTypes: PropTypes.array,
  language: PropTypes.string
}
LocationTypeList.defaultProps = {
  language: "es",
  locationType1: {
    name: "",
    id: "",
  },
  locationType2: {
    name: "",
    id: "",
  },
  locations: [],
}

export default LocationTypeList
