import React from "react"
import PropTypes from "prop-types"
import LocationResultCard from "../LocationResultCard"

import "./style.scss"

const LocationResultsList = props => {
  return (
    <div className="location-results-list">
      <div className="location-results-list__results">
        {props.locations.map((location, index) => {
          return (
            <LocationResultCard
              key={`location-results-list--${index}`}
              typeLabel={(location.acf.type || {}).name}
              typeDescription={(location.acf.type || {}).description}
              image={location.acf.locationImage}
              title={location.title}
              streetAddress={location.acf.streetAddress}
              city={location.acf.city}
              stateAbbreviation={location.acf.stateAbbreviation}
              zipCode={location.acf.zipCode}
              uri={location.uri}
              placeId={location.acf.map.placeId}
              placeAddress={`${location.acf.streetAddress} ${location.acf.city}, ${location.acf.stateAbbreviation} ${location.acf.zipCode}`}
            />
          )
        })}
      </div>
    </div>
  )
}

LocationResultsList.propTypes = {
  locations: PropTypes.array,
  language: PropTypes.string,
}
LocationResultsList.defaultProps = {
  locations: [],
  language: "es",
}

export default LocationResultsList
